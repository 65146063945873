import { createReducer, on } from '@ngrx/store';
import { formVisibilityAction } from './forms.actions';

// ----------- STATE - Formulário Detalhes da Conta
export const initialFormVisibilityState = {
  isVisible: false,
};

// ----------- REDUCER - Formulário Detalhes da Conta
export const FormVisibilityReducer = createReducer(
  initialFormVisibilityState,
  on(formVisibilityAction, (state, { isVisible }) => ({
    isVisible,
  }))
);
