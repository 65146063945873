import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter } from '@angular/router';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { StoreModule, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from "@sentry/angular";
import { QueryClient, provideAngularQuery } from '@tanstack/angular-query-experimental';
import { GtagModule } from 'angular-gtag';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideToastr } from 'ngx-toastr';
import { jwtInterceptor } from '../interceptors';
import { errorInterceptor } from '../interceptors/error.interceptor';
import { AuthTokenService } from '../services/auth-token.service';
import { FormVisibilityReducer } from '../store/components/forms/forms.reducer';
import { FormDetailReducer } from '../store/contas/contas.reducer';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideAnimations(),
    provideToastr(),
    provideStore(),
    importProvidersFrom(
      StoreModule.forRoot({}),
      StoreModule.forFeature('contas', FormDetailReducer),
      StoreModule.forFeature('forms', FormVisibilityReducer),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: false,
      }),
      GtagModule.forRoot({ trackingId: 'UA-YOUR_TRACKING_ID', trackPageviews: true }),
    ),
    provideCharts(withDefaultRegisterables()),
    provideAngularQuery(new QueryClient()),
    provideHttpClient(withInterceptors([jwtInterceptor, errorInterceptor])),
    importProvidersFrom([
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            return localStorage.getItem('token');
          },
        }
      }),
    ]),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      multi: true,
      deps: [AuthTokenService],
    },
  ],
};

export function initializerFactory(authService: AuthTokenService) {
  return () => authService.refreshToken();
}