import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthTokenService } from '../services/auth-token.service';

export const authGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const authSvc = inject(AuthTokenService);

  if (authSvc.isAuthenticated()) {
    return true;
  }

  try {
    const refreshed = await firstValueFrom(
      authSvc.refreshToken().pipe(
        map(res => !!res && !!res.body),
        catchError(() => [false])
      )
    );

    if (!refreshed) {
      router.navigate(['']);

      return false;
    }

    return true;
  } catch (error) {
    router.navigate(['']);

    return false;
  }
};
