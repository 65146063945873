import { Routes } from '@angular/router';
import { accountGuard, authGuard } from '../guards';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '',
    loadComponent: () =>
      import('./components/auth/auth.component').then(
        (mod) => mod.AuthComponent
      ),
      canActivate: [accountGuard],
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./modules/dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'callback_ml',
    loadComponent: () =>
      import('./modules/dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'contas',
    loadComponent: () =>
      import('./modules/contas/contas.component').then(
        (mod) => mod.ContasComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'metricas',
    loadComponent: () =>
      import('./modules/metricas/metricas.component').then(
        (mod) => mod.MetricasComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'pedidos',
    loadComponent: () =>
      import('./modules/orders/orders.component').then(
        (mod) => mod.OrdersComponent
      ),
    canActivate: [authGuard],
  },
];
