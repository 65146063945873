import { AsyncPipe, NgClass, NgIf, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { Component, Injectable, LOCALE_ID } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map } from 'rxjs';
import { AuthTokenService } from '../services/auth-token.service';
import { AuthComponent } from './components/auth/auth.component';
import { FormComponent } from './components/forms/form/form.component';
import { HeaderSubComponent } from './components/header/header-sub/header-sub.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuComponent } from './components/menu/menu.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { Gtag } from 'angular-gtag';

registerLocaleData(localePt);
// MatTableModule e MatPaginatorModule
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    AuthComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    HeaderSubComponent,
    FormComponent,
    AsyncPipe,
    RouterOutlet,
    NgClass,
    NgIf,
    MatTableModule,
    MatPaginatorModule,
    LoaderComponent,
    AsyncPipe
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  pageName = '';
  isMenuCollapsed = false;
  displayActionButton = false;

  formType = '';

  private mainLoding = new BehaviorSubject<boolean>(true);
  mainLoding$ = this.mainLoding.asObservable();

  constructor(
    private authTokenService: AuthTokenService,
    private readonly router: Router,
    private readonly store: Store<{
      contas: any;
      forms: any;
    }>,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const current = this.router.url.replace('/', '');
        this.getCurrentPageName(current);

        if (current === '' && this.hasToken) {
          this.router.navigate(['dashboard']);
        }
      }
    });
  }

  isFormOpened$ = this.store.select('forms').pipe(map((v) => v.isVisible));
  formDetailValues$ = this.store.select('contas').pipe(map((v) => v));

  ngOnInit() {
    this.formDetailValues$.subscribe({
      next: (value: { id: string }) => {
        if (value.id) {
          this.formType = 'account-detail';
        } else {
          this.formType = 'add-new';
        }
      },
    });

    setTimeout(() => {
      this.mainLoding.next(false);
    }, 200);
  }

  getCurrentPageName(pageName: string) {
    switch (pageName) {
      case 'dashboard':
        this.pageName = 'Início';
        this.displayActionButton = false;
        break;
      case 'contas':
        this.pageName = 'Contas';
        this.displayActionButton = true;
        break;
      case 'metricas':
        this.pageName = 'Métricas';
        this.displayActionButton = false;
        break;
      case 'pedidos':
        this.pageName = 'Pedidos';
        this.displayActionButton = false;
        break;
      default:
        this.pageName = '';
        break;
    }
  }

  get hasToken() {
    return this.authTokenService.hasToken();
  }
}
