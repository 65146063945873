import { createReducer, on } from '@ngrx/store';
import { formDetailAction, resetDetailFormAction } from './contas.actions';

// ----------- STATE - Formulário Detalhes da Conta
export const initialDetailFormState = {
  id: '',
  name: '',
  status: '',
  user: '',
  externalId: '',
  fullName: '',
  marketplace: {
    id: 0,
    name: '',
  },
  updatedAt: '',
};

// ----------- REDUCER - Formulário Detalhes da Conta
export const FormDetailReducer = createReducer(
  initialDetailFormState,
  on(formDetailAction, (state, account) => ({
    state,
    ...account
  })),
  on(resetDetailFormAction, (state) => ({
    ...initialDetailFormState // Reset state to initial values
  }))
);
