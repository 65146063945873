import { Injectable, Inject, Directive, Renderer2, Input, ElementRef, NgModule } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { tap, filter } from 'rxjs/operators';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
class Gtag {
    /**
     * @param {?} gaConfig
     * @param {?} router
     */
    constructor(gaConfig, router) {
        this.router = router;
        this.mergedConfig = Object.assign({ trackPageviews: true }, gaConfig);
        if (this.mergedConfig.trackPageviews) {
            router.events
                .pipe(filter(event => event instanceof NavigationEnd), tap(event => {
                this.pageview();
            }))
                .subscribe();
        }
    }
    /**
     * @param {?} action
     * @param {?=} params
     * @return {?}
     */
    event(action, params = {}) {
        // try/catch to avoid cross-platform issues
        try {
            gtag('event', action, params);
            this.debug('event', this.mergedConfig.trackingId, action, params);
        }
        catch (/** @type {?} */ err) {
            console.error('Google Analytics event error', err);
        }
    }
    /**
     * @param {?=} params
     * @return {?}
     */
    pageview(params) {
        try {
            const /** @type {?} */ defaults = {
                page_path: this.router.url,
                page_title: 'Angular App',
                page_location: window.location.href
            };
            params = Object.assign({}, defaults, params);
            gtag('config', this.mergedConfig.trackingId, params);
            this.debug('pageview', this.mergedConfig.trackingId, params);
        }
        catch (/** @type {?} */ err) {
            console.error('Google Analytics pageview error', err);
        }
    }
    /**
     * @param {?} params
     * @return {?}
     */
    config(params) {
        try {
            gtag('config', this.mergedConfig.trackingId, (params = {}));
        }
        catch (/** @type {?} */ err) {
            console.error('Google Analytics config error', err);
        }
    }
    /**
     * @param {?} params
     * @return {?}
     */
    set(params) {
        try {
            gtag('set', (params = {}));
        }
        catch (/** @type {?} */ err) {
            console.error('Google Analytics set error', err);
        }
    }
    /**
     * @param {...?} msg
     * @return {?}
     */
    debug(...msg) {
        if (this.mergedConfig.debug) {
            console.log('angular-gtag:', ...msg);
        }
    }
}
Gtag.decorators = [
    { type: Injectable },
];
/** @nocollapse */
Gtag.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: ['config',] },] },
    { type: Router, },
];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
class GtagEventDirective {
    /**
     * @param {?} gtag
     * @param {?} renderer
     * @param {?} el
     */
    constructor(gtag, renderer, el) {
        this.gtag = gtag;
        this.renderer = renderer;
        this.el = el;
    }
    /**
     * @return {?}
     */
    ngAfterViewInit() {
        try {
            this.renderer.listen(this.el.nativeElement, this.trackOn, () => {
                this.gtag.event(this.action || this.trackOn, Object.assign({ event_category: this.category }, this.params));
            });
        }
        catch (/** @type {?} */ err) {
            console.error(err);
        }
    }
}
GtagEventDirective.decorators = [
    { type: Directive, args: [{
                selector: '[gtagEvent]'
            },] },
];
/** @nocollapse */
GtagEventDirective.ctorParameters = () => [
    { type: Gtag, },
    { type: Renderer2, },
    { type: ElementRef, },
];
GtagEventDirective.propDecorators = {
    "trackOn": [{ type: Input },],
    "action": [{ type: Input },],
    "category": [{ type: Input },],
    "params": [{ type: Input },],
};

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
class GtagModule {
    /**
     * @param {?} config
     * @return {?}
     */
    static forRoot(config) {
        return {
            ngModule: GtagModule,
            providers: [Gtag, { provide: 'config', useValue: config }]
        };
    }
}
GtagModule.decorators = [
    { type: NgModule, args: [{
                declarations: [GtagEventDirective],
                exports: [GtagEventDirective]
            },] },
];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
/**
 * Generated bundle index. Do not edit.
 */

export { Gtag, GtagEventDirective, GtagModule };

