<app-header *ngIf="hasToken"></app-header>
<div class="wrapper" [ngClass]="{ 'is-collapsed': isMenuCollapsed, 'no-auth': !hasToken }">
  <app-menu *ngIf="hasToken" [isMenuCollapsed]="isMenuCollapsed"
    (toggleMenuEmitter)="isMenuCollapsed = !isMenuCollapsed" [ngClass]="{ 'no-auth': !hasToken }"></app-menu>
  <div class="wrapper__content" [ngClass]="{ 'no-auth': !hasToken }">
    <router-outlet></router-outlet>
    <app-form *ngIf="isFormOpened$ | async" [data]="formDetailValues$ | async" [formType]="formType"
      [isFormOpened]="isFormOpened$ | async"></app-form>
  </div>
</div>
@if(mainLoding$ | async) {
<div id="loadingIndicator" class="container-loader-logo">
  <div>
    <img src="../../../assets/images/icons/global-loading.svg" alt="carregando">
  </div>
  <img src="../../../assets/images/icons/capivara-logo-loading.svg" alt="capivara PRO">
</div>
<div id="loadingIndicatorBackground" class="form-backdrop-loading"></div>
}