import { CanActivateFn, Router } from '@angular/router';
import { inject } from "@angular/core";
import { AuthTokenService } from '../services/auth-token.service';

export const accountGuard: CanActivateFn = () => {
    if (inject(AuthTokenService).isAuthenticated()) {
        inject(Router).navigate(['/dashboard']);

        return false;
    }

    return true;
};